/** A local storage variable which is used to properly route the app. */
export const AfterLoginGoTo = "afterLoginGoTo";
/** A local storage variable which is used to properly route the app. */
export const OAuthState = "oAuthState";
/** A local storage variable which is used to manage token expiration timestamps. */
export const SessionExpirationVar = "sessionExpiration";
/** Milliseconds for simple timestamp math. */
export const OneDayInMillis = 24 * 60 * 60 * 1000;
/** Milliseconds for five minutes */
export const FiveMinutesInMillis = 5 * 60 * 1000;
/** Milliseconds for one hour */
export const OneHourInMillis = 60 * 60 * 1000;
/** Milliseconds for one minute */
export const OneMinuteInMillis = 60 * 1000;
/** Milliseconds for one second. */
export const OneSecondInMillis = 1000;
/** Mapping between the common names for scan type and the job definition. */
export const ScanTypes = {
  "SSL - Crypto Scanning": "solis_ssl",
  "ZAP - Web Application Scanning": "solis_zap",
  "Target Connectivity": "target_connectivity",
  "GitHub Dependabot Scanning": "git_dependabot",
};
/** Mapping between the common names for scan type and the job definiton for git scans. */
export const ScanTypesGit = {
  "Target Connectivity": "target_connectivity",
  "GitHub Dependabot Scanning": "git_dependabot",
};
/** Mapping between the common names for scan type and the job definiton for web applications and api. */
export const ScanTypesWebApi = {
  "SSL - Crypto Scanning": "solis_ssl",
  "ZAP - Web Application Scanning": "solis_zap",
  "Target Connectivity": "target_connectivity",
};
/**
 * Base state for an app before any ID has been
 * set and any fetch has been made.
 */
export const baseAppState = {
  _id: "",
  name: "",
  external_ids: {
    csdl_id: "",
    esp_sys_id: "",
  },
  business_unit: "",
  created_at: "",
  created_by: "",
  last_updated: "",
  service_tokens: [],
  scan_schedules: [],
  external_file_quota_mb: 1024.0,
  remaining_external_file_quota_mb: 1024.0,
  external_files: [],
  tags: {},
  targets: [],
  users: [],
  scan_config_quota: 0,
};

/** Base state for a target document. */
export const baseTargetState = {
  _id: "",
  app_roles: [],
  created_at: "",
  created_by: "",
  first_scanned: "",
  host_name: "",
  last_scanned: "",
  last_updated: "",
  name: "",
  owner_app_id: "",
  path: "",
  port: 0,
  protocol: "",
  tags: {},
  type: "",
};

/** The base state for a new scan schedule being crafted. */
export const baseScanSchedule = {
  name: "",
  description: "",
  scan_type: "",
  scan_config_ids: [],
  enabled: true,
};

/** The base state for a new access rule being crafted. */
export const baseAccessRule = {
  name: "",
  justification: "",
  rule_type: "allow",
  regexp: "",
  enabled: true,
  target_types: null,
};

/** The base state for a new personal access token being created. */
export const basePersonalAccessToken = {
  name: "",
  days_valid: 1,
};

/** The cookie keys used for session secret type. Data pulled from Playwright (https://playwright.dev/python/docs/api/class-browsercontext#browser-context-cookies) */
export const sessionKeys = [
  "name",
  "value",
  "domain",
  "path",
  "expires",
  "httpOnly",
  "secure",
  "sameSite",
];

/** Valid statuses for triage. */
export const triageStatuses = [
  "UNREVIEWED",
  "REVIEWED",
  "FALSE POSITIVE",
  "REMEDIATED",
  "ACCEPTED RISK",
];
/** Expected valid severities of a result. */
export const severities = [
  "CRITICAL",
  "HIGH",
  "MEDIUM",
  "LOW",
  "INFORMATIONAL",
  "NONE",
];

// Possible result instance statuses
export const resultInstanceStatuses = ["PASS", "FAIL"];
// Possible result statuses
export const resultStatuses = ["FAIL", "REGRESSION", "REMEDIATED"];

// Regex for IDs
export const idRegex = /^[0-9a-fA-F]{24}$/;

export const csdlIDRegex = /^\d{5,6}$/;

export const espSysIDRegex = /^[0-9a-fA-F]{32}$/;

// Possible scan statuses
export const scanStatuses = [
  "SUCCEEDED",
  "ERRORED",
  "INTERNAL ERROR",
  "TERMINATED",
  "SUBMITTED",
  "RUNNING",
];

// Scan statuses that signify that the scan is now in a terminal state
export const terminalStatuses = [
  "SUCCEEDED",
  "ERRORED",
  "INTERNAL ERROR",
  "TERMINATED",
];

export const chariotFilters = [
  "External Public Cloud Environment Scans",
  "Internal On-Prem Environment Scans",
];

export const businessUnits = [
  "Application Dynamics",
  "Asset Licensing & Entitlement",
  "Broadsoft",
  "Business Process Automation",
  "Cisco Enterprise",
  "Collaboration",
  "Computing Systems",
  "Customer Experience",
  "Data Center Networking",
  "Duo",
  "Engineering Services",
  "Enterprise Routing",
  "Enterprise Switching",
  "IoT",
  "Information Technology (IT)",
  "Leasing and Finance",
  "Legal",
  "Meraki",
  "Mergers & Acquisitions",
  "Network Services",
  "Productivity Applications",
  "Sales",
  "Security Business Group",
  "Security & Trust Organization",
  "Thousand Eyes",
  "Umbrella",
  "WebEx",
  "Wireless",
  "Workforce Services",
];

/**
 * A config object with nothing filled in. Used to maintain consistent
 * state throughout the setup process.
 */
export const baseConfig = {
  url_list: [],
  exclude_urls: [],
  context_include_urls: [],
  external_files: [],
  har_files: [],
  restrict_spiders: false,
  ajax_click_default_elems: true,
  ajax_windows_count: "",
  active_scanning_thread_count: "",
  spider_duration: "",
  scan_req_timeout: "",
  ajax_event_wait: "",
  ajax_reload_wait: "",
  max_alerts_per_rule: "",
  attack_cookies: false,
  attack_url_paths: false,
  attack_url_query_params: false,
  use_robots_txt: true,
  use_sitemap_xml: true,
  file_asset_path: "",
  file_asset_url: "",
  auth_method: "",
  auth_url: "",
  username_field: "",
  password_field: "",
  username: "",
  password: "",
  token_auth_key: "",
  auth_access_token: "",
  mtls_certificate_password: "",
  mtls_certificate_pkcs12_base64: "",
  session_json_base64: "",
  using_jwt: false,
  custom_headers: [],
  extra_pii: [],
  allowed_url_params: [],
  session_token_name: "",
  session_csrf: false,
  csrf_token_names: [],
  cert_lifetime_threshold: 25,
  script_engine: "",
  script_file_path: "",
  script_params: "",
  exclude: [],
  required_http_methods: [],
  extra_form_auth_data: "",
  canary_url: "",
  canary_url_allow_redirects: false,
};

// Config items used to maintain consistent state through the entire scan config
// build process and build final functional config items.
export const baseMetaConfig = {
  selectedScript: "",
  rawScriptParams: {},
};

/** Regex to match HAR files with extension .har */
export const harRegex = new RegExp(".*.har$");

/** Regex to match authentication scripts with extensions like .json, .yaml, and .txt */
export const fileAssetPathRegex = new RegExp(".*.(json|yaml|txt|yml)$");

/** Regex to match MACRO Rapid files with extension .rec */
export const macroRapidRegex = new RegExp(".*.rec$");

/** Regex to match SELENIUM Rapid files with extension .rec */
export const seleniumRapidRegex = new RegExp(".*.side$");

/** Regex to match TRAFFIC Rapid Files authentication with extensions like .har", ".saz", ".trec", ".txt", ".xml"*/
export const trafficRapidRegex = new RegExp(".*.(har|saz|trec|txt|xml)$");

/** Regex to match mTLS Certificate Files with extensions .p12 */
export const mtlsRegex = new RegExp(".*.p12$");

/** Regex to match authentication scripts with extensions like .groovy, .zst, .js, and .jsm */
export const scriptRegex = new RegExp(".*.(groovy|zst|js|jsm)$");

/** Regex to match configuration files with extensions .json, .yaml, .yml */
export const configRegex = new RegExp(".*.(json|yaml|yml)");

/** Regex to match S3 URLs */
export const s3URLRegex = new RegExp(/.*\.s3\.amazonaws.com\/.*/);

/** Regex to check base64 */
export const b64Regex = new RegExp(
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/
);

/** Valid HTTP methods for setting configuration */
export const VALID_HTTP_METHODS = [
  "CONNECT",
  "DELETE",
  "GET",
  "HEAD",
  "OPTIONS",
  "PATCH",
  "POST",
  "PUT",
  "TRACE",
];

export const SOLIS_ERROR_DOCUMENTATION_LINK_BASE =
  "https://wwwin-github.cisco.com/pages/Solis/docs/user-guides/error_codes";

// Enums for types at the bottom
export const filterableAttrsTypes = {
  ID: "ID",
  STRICT: "STRICT",
  STRING: "STRING",
  DATE: "DATE",
  NUMBER: "NUMBER",
};

export const filterComparatorTypes = {
  LIKE: "LIKE",
  ">=": ">=",
  "<=": "<=",
  "=": "=",
};

// Copied from solistools/solistools/constants.py
export const resultSeverityDetails = {
  CRITICAL: { default: 9.5, min: 9.0, max: 10.0 },
  HIGH: { default: 8.0, min: 7.0, max: 8.9 },
  MEDIUM: { default: 5.0, min: 4.0, max: 6.9 },
  LOW: { default: 2.0, min: 0.1, max: 3.9 },
  INFORMATIONAL: { default: 0.05, min: 0.01, max: 0.09 },
  NONE: { default: 0.0, min: 0.0, max: 0.0 },
};

export const quickFiltersResults = [
  {
    name: "Status: FAIL",
    filterQuery: {
      status: "FAIL",
    },
  },
  {
    name: "Status: REGRESSION",
    filterQuery: {
      status: "REGRESSION",
    },
  },
  {
    name: "Status: REMEDIATED",
    filterQuery: {
      status: "REMEDIATED",
    },
  },
  {
    name: "Triaged State: UNREVIEWED",
    filterQuery: {
      "triage_details.status.state": "UNREVIEWED",
    },
  },
  {
    name: "Status: FAIL and Severity >= HIGH",
    filterQuery: {
      status: "FAIL",
      severity_value: {
        $gte: resultSeverityDetails.HIGH.min,
      },
    },
  },
  {
    name: "Status: FAIL and Severity >= MEDIUM",
    filterQuery: {
      status: "FAIL",
      severity_value: {
        $gte: resultSeverityDetails.MEDIUM.min,
      },
    },
  },
];

export const quickFiltersScans = [
  {
    name: "Status: FAIL",
    filterQuery: {
      "scan_result_instance.status": "FAIL",
    },
  },
  {
    name: "Status: PASS",
    filterQuery: {
      "scan_result_instance.status": "PASS",
    },
  },
  {
    name: "Triaged State: UNREVIEWED",
    filterQuery: {
      "triage_details.status.state": "UNREVIEWED",
    },
  },
  {
    name: "Status: FAIL and Severity >= HIGH",
    filterQuery: {
      "scan_result_instance.status": "FAIL",
      severity_value: {
        $gte: resultSeverityDetails.HIGH.min,
      },
    },
  },
  {
    name: "Status: FAIL and Severity >= MEDIUM",
    filterQuery: {
      "scan_result_instance.status": "FAIL",
      severity_value: {
        $gte: resultSeverityDetails.MEDIUM.min,
      },
    },
  },
];

export const filterableAttrsScans = [
  {
    key: "app_id",
    name: "App ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "target_id",
    name: "Target ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "chariot_id",
    name: "Chariot ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "scan_type",
    name: "Scan Type",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "created_by",
    name: "Created By",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "start_time",
    name: "Start Time",
    type: filterableAttrsTypes.DATE,
    defaultComparator: filterComparatorTypes[">="],
  },
  {
    key: "stop_time",
    name: "Stop Time",
    type: filterableAttrsTypes.DATE,
    defaultComparator: filterComparatorTypes["<="],
  },
  {
    key: "status",
    name: "Status",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
];

export const filterableAttrsScansDropdown = [
  {
    key: "chariot_id",
    name: "Chariot ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "scan_type",
    name: "Scan Type",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "created_by",
    name: "Created By",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "status",
    name: "Status",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
];

export const filterableAttrsAppScansDropdown = [
  {
    key: "target_id",
    name: "Target ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  ...filterableAttrsScansDropdown,
];

export const filterableAttrsAllScansDropdown = [
  {
    key: "app_id",
    name: "App ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  ...filterableAttrsAppScansDropdown,
];

/** Filterable attributes for Results Page. */
export const filterableAttrsResults = [
  {
    key: "severity",
    name: "Severity",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "status",
    name: "Status",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "latest_instance.status",
    name: "Latest Instance Status",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "scanner_name",
    name: "Scanner Name",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "latest_instance.container_name",
    name: "Scan Type",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "endpoint",
    name: "Endpoint",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "triage_details.status.state",
    name: "Triaged State",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "triage_details.status.severity",
    name: "Triaged Severity",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "mappings.wasc_ids",
    name: "WASC Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "mappings.cwe_ids",
    name: "CWE Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "mappings.psb_mappings",
    name: "PSB Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "mappings.scf_mappings",
    name: "CCF Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "severity_value",
    name: "Severity Value",
    type: filterableAttrsTypes.NUMBER,
    defaultComparator: filterComparatorTypes[">="],
  },
];

/** Filterable attributes for Scans Page. */
export const filterableAttrsScansResults = [
  {
    key: "severity",
    name: "Severity",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "scan_result_instance.status",
    name: "Status",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "scanner_name",
    name: "Scanner Name",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "endpoint",
    name: "Endpoint",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "triage_details.status.state",
    name: "Triaged State",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "triage_details.status.severity",
    name: "Triaged Severity",
    type: filterableAttrsTypes.STRICT,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "mappings.wasc_ids",
    name: "WASC Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "mappings.cwe_ids",
    name: "CWE Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "mappings.psb_mappings",
    name: "PSB Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "mappings.scf_mappings",
    name: "CCF Mappings",
    type: filterableAttrsTypes.STRING,
    defaultComparator: filterComparatorTypes.LIKE,
  },
  {
    key: "severity_value",
    name: "Severity Value",
    type: filterableAttrsTypes.NUMBER,
    defaultComparator: filterComparatorTypes[">="],
  },
];

export const filterableAttrsAllResults = [
  {
    key: "owner_app_id",
    name: "App ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  {
    key: "target_id",
    name: "Target ID",
    type: filterableAttrsTypes.ID,
    defaultComparator: filterComparatorTypes["="],
  },
  ...filterableAttrsResults,
];

/** Passive Scanners keys, names, and descriptions. */
export const passive_scanners = [
  {
    key: "2",
    name: "Private IP Disclosure",
    description:
      "A private IP or an Amazon EC2 private hostname has been found in the HTTP response body.",
  },
  {
    key: "3",
    name: "Session ID in URL Rewrite",
    description:
      "Session ID in the URL rewrite or the referer exposes the session ID.",
  },
  {
    key: "10003",
    name: "Vulnerable JS Library",
    description:
      "The identified library ExampleLibrary, version x.y.z is vulnerable.",
  },
  {
    key: "10009",
    name: "In Page Banner Information Leak",
    description:
      "The server returned a version banner string in the response content.",
  },
  {
    key: "10010",
    name: "Cookie No HttpOnly Flag",
    description:
      "A cookie has been set without the HttpOnly flag, which means that the cookie can be accessed by JavaScript.",
  },
  {
    key: "10011",
    name: "Cookie Without Secure Flag",
    description:
      "A cookie has been set without the secure flag, which means that the cookie can be accessed via unencrypted connections.",
  },
  {
    key: "10015",
    name: "Re-examine Cache-control Directives",
    description:
      "The cache-control header has not been set properly or is missing, allowing the browser and proxies to cache content.",
  },
  {
    key: "10017",
    name: "Cross-Domain JavaScript Source File Inclusion",
    description:
      "The page includes one or more script files from a third-party domain.",
  },
  {
    key: "10019",
    name: "Content-Type Header Missing",
    description: "The Content-Type header was either missing or empty.",
  },
  {
    key: "10020",
    name: "Anti-clickjacking Header",
    description:
      "Missing Anti-clickjacking header, multiple X-Frame-Options header entries, X-Frame-Options defined via META (non-compliant with spec), or X-Frame-Options setting malformed.",
  },
  {
    key: "10021",
    name: "X-Content-Type-Options Header Missing",
    description:
      "The Anti-MIME-Sniffing header X-Content-Type-Options was not set to nosniff.",
  },
  {
    key: "10023",
    name: "Information Disclosure - Debug Error Messages",
    description:
      "The response appeared to contain common error messages returned by platforms such as ASP.NET, and Web-servers such as IIS and Apache.",
  },
  {
    key: "10024",
    name: "Information Disclosure - Sensitive Information in URL",
    description:
      "The request appeared to contain sensitive information leaked in the URL.",
  },
  {
    key: "10025",
    name:
      "Information Disclosure - Sensitive Information in HTTP Referrer Header",
    description:
      "The HTTP header may have leaked a potentially sensitive parameter to another domain.",
  },
  {
    key: "10026",
    name: "HTTP Parameter Override",
    description:
      "Unspecified form action: HTTP parameter override attack potentially possible.",
  },
  {
    key: "10027",
    name: "Information Disclosure - Suspicious Comments",
    description:
      "The response appears to contain suspicious comments which may help an attacker.",
  },
  {
    key: "10028",
    name: "Open Redirect",
    description:
      "Open redirects are one of the OWASP 2010 Top Ten vulnerabilities.",
  },
  {
    key: "10029",
    name: "Cookie Poisoning",
    description:
      "This check looks at user-supplied input in query string parameters and POST data to identify where cookie parameters might be controlled.",
  },
  {
    key: "10030",
    name: "User Controllable Charset",
    description:
      "This check looks at user-supplied input in query string parameters and POST data to identify where Content-Type or meta tag charset declarations might be user-controlled.",
  },
  {
    key: "10031",
    name: "User Controllable HTML Element Attribute (Potential XSS)",
    description:
      "This check looks at user-supplied input in query string parameters and POST data to identify where certain HTML attribute values might be controlled.",
  },
  {
    key: "10032",
    name: "Viewstate",
    description:
      "Potential IP addresses found in the viewstate, emails found in the viewstate, old Asp.Net version in use, viewstate without MAC signature (unsure or sure), split viewstate in use.",
  },
  {
    key: "10033",
    name: "Directory Browsing",
    description: "It is possible to view a listing of the directory contents.",
  },
  {
    key: "10034",
    name: "Heartbleed OpenSSL Vulnerability (Indicative)",
    description:
      "The TLS and DTLS implementations in OpenSSL 1.0.1 before 1.0.1g do not properly handle Heartbeat Extension packets.",
  },
  {
    key: "10035",
    name: "Strict-Transport-Security Header",
    description:
      "HSTS is a web security policy mechanism whereby a web server declares that complying user agents are to interact with it using only secure HTTPS connections.",
  },
  {
    key: "10036",
    name: "HTTP Server Response Header",
    description:
      "Server leaks its webserver application via 'Server' HTTP response header field, or server leaks version information via 'Server' HTTP response header field.",
  },
  {
    key: "10037",
    name:
      "Server Leaks Information via X-Powered-By HTTP Response Header Field(s)",
    description:
      "The web/application server is leaking information via one or more “X-Powered-By” HTTP response headers.",
  },
  {
    key: "10038",
    name: "Content Security Policy (CSP) Header Not Set",
    description: "Issues with the CSP (Content Security Policy).",
  },
  {
    key: "10039",
    name: "X-Backend-Server Header Information Leak",
    description:
      "The server is leaking information pertaining to backend systems (such as hostnames or IP addresses).",
  },
  {
    key: "10040",
    name: "Secure Pages Include Mixed Content",
    description:
      "The page includes mixed content, that is content accessed via HTTP instead of HTTPS.",
  },
  {
    key: "10041",
    name: "HTTP to HTTPS Insecure Transition in Form Post",
    description:
      "This check looks for insecure HTTP pages that host HTTPS forms.",
  },
  {
    key: "10042",
    name: "HTTPS to HTTP Insecure Transition in Form Post",
    description:
      "This check identifies secure HTTPS pages that host insecure HTTP forms.",
  },
  {
    key: "10043",
    name: "User Controllable JavaScript Event (XSS)",
    description:
      "This check looks at user-supplied input in query string parameters and POST data to identify where certain HTML attribute values might be controlled.",
  },
  {
    key: "10044",
    name: "Big Redirect Detected (Potential Sensitive Information Leak)",
    description:
      "The server has responded with a redirect that seems to provide a large response.",
  },
  {
    key: "10049",
    name: "Content Cacheability",
    description:
      "Non-storable content, storable but non-cacheable content, or storable and cacheable content.",
  },
  {
    key: "10050",
    name: "Retrieved from Cache",
    description: "The content was retrieved from a shared cache.",
  },
  {
    key: "10052",
    name: "X-ChromeLogger-Data (XCOLD) Header Information Leak",
    description:
      "The server is leaking information through the X-ChromeLogger-Data (or X-ChromePhp-Data) response header.",
  },
  {
    key: "10054",
    name: "Cookie without SameSite Attribute",
    description:
      "A cookie has been set without the SameSite attribute, which means that the cookie can be sent as a result of a cross-site request.",
  },
  {
    key: "10055",
    name: "CSP",
    description: "Issues with the content security policy.",
  },
  {
    key: "10056",
    name: "X-Debug-Token Information Leak",
    description:
      "The response contained an X-Debug-Token or X-Debug-Token-Link header.",
  },
  {
    key: "10057",
    name: "Username Hash Found",
    description: "A hash of a username (admin) was found in the response.",
  },
  {
    key: "10061",
    name: "X-AspNet-Version Response Header",
    description:
      "Server leaks information via “X-AspNet-Version”/“X-AspNetMvc-Version” HTTP response header field(s).",
  },
  {
    key: "10062",
    name: "PII Disclosure",
    description:
      "The response contains Personally Identifiable Information, such as CC number, SSN and similar sensitive data.",
  },
  {
    key: "10063",
    name: "Permissions Policy Header Not Set",
    description:
      "Permissions policy header not set, or deprecated feature policy header set",
  },
  {
    key: "10094",
    name: "Base64 Disclosure",
    description:
      "ASP.NET viewState disclosure, ASP.NET viewState integrity, or base64 disclosure.",
  },
  {
    key: "10096",
    name: "Timestamp Disclosure",
    description: "A timestamp was disclosed by the application/web server.",
  },
  {
    key: "10097",
    name: "Hash Disclosure",
    description: "A hash was disclosed by the web server.",
  },
  {
    key: "10098",
    name: "Cross-Domain Misconfiguration",
    description:
      "Web browser data loading may be possible, due to a CORS misconfiguration on the web server.",
  },
  {
    key: "10099",
    name: "Source Code Disclosure - PHP",
    description:
      "Application Source Code was disclosed by the web server - PHP",
  },
  {
    key: "10105",
    name: "Weak Authentication Method",
    description:
      "HTTP basic or digest authentication has been used over an unsecured connection.",
  },
  {
    key: "10108",
    name: "Reverse Tabnabbing",
    description:
      "At least one link on this page is vulnerable to Reverse tabnabbing.",
  },
  {
    key: "10109",
    name: "Modern Web Application",
    description: "The application appears to be a modern web application.",
  },
  {
    key: "10202",
    name: "Absence of Anti-CSRF Tokens",
    description: "No token to prevent CSRF attack.",
  },
  { key: "50001", name: "Passive Scan scripts", description: "" },
  { key: "50003", name: "Stats passive scan rule", description: "" },
  { key: "60000", name: "Example simple passive rule", description: "" },
  { key: "60001", name: "Example file passive rule", description: "" },
  {
    key: "70001",
    name: "Solis Strict-Transport-Security Header Scanner",
    description:
      "Verify the HSTS Headers as specified by SEC-HTP-HSTS-2 (FR3).",
  },
  {
    key: "70002",
    name: "Solis Content Type Charset Scanner",
    description:
      "Verify the charset is included in the Content-Type header (Part of SEC-WEB-RESP-3).",
  },
  {
    key: "70003",
    name: "Solis Content Security Policy Scanner",
    description:
      "Verify a number of elements within the Content Security Policy header.",
  },
  {
    key: "70004",
    name: "Solis Clickjacking Scanner",
    description:
      "Verify elements within the Content-Security-Policy and X-Frame-Options headers to ensure defense against Clickjacking.",
  },
  {
    key: "70005",
    name: "Solis HTTP to HTTPS Passive Scanner",
    description:
      "Verifies that HTTP requests are redirected to HTTPS as specified by SEC-HTP-HSTS-2 (FR2).",
  },
  {
    key: "70006",
    name: "Solis Sensitive URL Passive Scanner",
    description:
      "Verifies no URLs found after spidering contain sensitive information as specified by SEC-WEB-URLPARM-2.",
  },
  {
    key: "70007",
    name: "Solis Content Security Policy Scanner v3",
    description:
      "Verifies that Content Secuirty Policy headers have required directives with values that are trusted and limited in scope.",
  },
  {
    key: "70008",
    name: "Solis Clickjacking Csp Scanner",
    description:
      "Verify elements within the Content-Security-Policy headers to ensure defense against Clickjacking.",
  },
  {
    key: "70009",
    name: "Solis Clickjacking Xfo Scanner",
    description:
      "Verify elements within the X-Frame-Options headers to ensure defense against Clickjacking.",
  },
  {
    key: "70010",
    name: "Solis Content Security Policy Scanner v4",
    description:
      "Verifies that Content Secuirty Policy headers have required directives with values that are trusted and limited in scope.",
  },
  {
    key: "70011",
    name: "Solis Strict Transport Security Scanner",
    description:
      "Verifies the presence of the Strict-Tansport-Security header to ensure compliance with SEC-HTP-HSTS-FR2.",
  },
  {
    key: "70012",
    name: "Solis CSRF URL Parameter Scanner",
    description:
      "Verifies that anti csrf tokens are not present in the url query parameters as specified by SEC-WEB-CSRF-FR8.",
  },
  {
    key: "90001",
    name: "Insecure JSF ViewState",
    description:
      "The response at the following URL contains a ViewState value that has no cryptographic protections.",
  },
  {
    key: "90002",
    name: "Java Serialization Object",
    description: "Java Serialization seems to be in use.",
  },
  {
    key: "90003",
    name: "Sub Resource Integrity Attribute Missing",
    description:
      "The integrity attribute is missing on a script or link tag served by an external server.",
  },
  {
    key: "90011",
    name: "Charset Mismatch",
    description:
      "The HTTP Content-Type header declares a charset different from the charset defined by the body of the HTML or XML.",
  },
  {
    key: "90022",
    name: "Application Error Disclosure",
    description:
      "This page contains an error/warning message that may disclose sensitive information.",
  },
  {
    key: "90033",
    name: "Loosely Scoped Cookie",
    description: "Cookies can be scoped by domain or path.",
  },
];

/** Active Scanners keys, names, and descriptions. */
export const active_scanners = [
  { key: "6", name: "Path Traversal", description: "" },
  {
    key: "20012",
    name: "Anti-CSRF Tokens Check",
    description: "No token to prevent CSRF attack.",
  },
  { key: "20016", name: "Cross-Domain Misconfiguration", description: "" },
  { key: "20019", name: "External Redirect", description: "" },
  {
    key: "40009",
    name: "Server Side Include",
    description:
      "Certain parameters may cause Server Side Include commands to be executed.",
  },
  { key: "40012", name: "Cross Site Scripting (Reflected)", description: "" },
  { key: "40014", name: "Cross Site Scripting (Persistent)", description: "" },
  {
    key: "40016",
    name: "Cross Site Scripting (Persistent) - Prime",
    description: "",
  },
  {
    key: "40017",
    name: "Cross Site Scripting (Persistent) - Spider",
    description: "",
  },
  {
    key: "40018",
    name: "SQL Injection",
    description: "SQL injection may be possible.",
  },
  {
    key: "40019",
    name: "SQL Injection - MySQL",
    description: "SQL injection may be possible.",
  },
  {
    key: "40020",
    name: "SQL Injection - Hypersonic SQL",
    description: "SQL injection may be possible.",
  },
  {
    key: "40021",
    name: "SQL Injection - Oracle",
    description: "SQL injection may be possible.",
  },
  {
    key: "40022",
    name: "SQL Injection - PostgreSQL",
    description: "SQL injection may be possible.",
  },
  {
    key: "40024",
    name: "SQL Injection - SQLite",
    description: "SQL injection may be possible.",
  },
  { key: "40026", name: "Cross Site Scripting (DOM Based)", description: "" },
  {
    key: "40027",
    name: "SQL Injection - MsSQL",
    description: "SQL injection may be possible.",
  },
  {
    key: "80001",
    name: "Solis Code Injection Plugin",
    description: "Offers enhancements to scanner 90019.",
  },
  {
    key: "80002",
    name: "Solis XPath Injection Plugin",
    description: "Offers enhancements to scanner 90021.",
  },
  {
    key: "80003",
    name: "Solis Path Traversal Plugin",
    description: "Offers enhancements to scanner 6.",
  },
  {
    key: "80004",
    name: "Solis XML External Entity Plugin",
    description: "Offers enhancements to scanner 90023.",
  },
  {
    key: "80005",
    name: "Solis Strict-Transport-Security Redirect Scanner",
    description:
      "Verifying http redirects as required for SEC-HTP-HSTS-2 (FR2).",
  },
  {
    key: "80006",
    name: "Solis XSS Reflection Scanner",
    description:
      "Additional Payloads not covered by scanner 40012 as required for SEC-WEB-XSS-2.",
  },
  {
    key: "80008",
    name: "Solis JWT No Signature Plugin",
    description:
      "Strip signatures from JWTs and resend them to determine if an application under test is correctly verifying JWT signatures.",
  },
  {
    key: "80009",
    name: "Solis JWT Payload Injection Plugin",
    description:
      "Modify the payload of JWTs and resend them to determine if the application under test is correctly verifying JWTs.",
  },
  {
    key: "90019",
    name: "Server Side Code Injection",
    description: "PHP code injection or ASP code injection",
  },
  {
    key: "90020",
    name: "Remote OS Command Injection",
    description:
      "Attack technique used for unauthorized execution of operating system commands.",
  },
  {
    key: "90021",
    name: "XPath Injection",
    description:
      "XPath Injection is an attack technique used to exploit applications that construct XPath.",
  },
  {
    key: "90023",
    name: "XML External Entity Attack",
    description:
      "This technique takes advantage of a feature of XML to build documents dynamically at the time of processing.",
  },
];

// Mappings the UI knows how to format and display
export const knownMappings = {
  cwe_ids: {
    getName: (mapping) => `CWE-${mapping}`,
    getLink: (mapping) =>
      `https://cwe.mitre.org/data/definitions/${mapping}.html`,
  },
  psb_mappings: {
    getName: (mapping) => `${mapping}`,
    getLink: (mapping) =>
      `https://wwwin-si.cisco.com/psb-requirements#${mapping}`,
  },
  scf_mappings: {
    getName: (mapping) => `${mapping}`,
    getLink: (mapping) => `https://wwwin-si.cisco.com/scf#${mapping}`,
  },
  wasc_ids: {
    getName: (mapping) => `WASC-${mapping}`,
    getLink: () =>
      `http://projects.webappsec.org/w/page/13246974/Threat%20Classification%20Reference%20Grid`,
  },
};

export const defaultFilterAllResults = {
  severity_value: {
    $gte: resultSeverityDetails.MEDIUM.min,
  },
};

export const defaultFilterResults = {};

export const defaultFilterScans = {};

export const defaultPageSize = 25;

export const pageSizeOptions = [10, defaultPageSize, 35, 50];

export const uiVersionPath = "/v1";

export const destinationTypes = [
  "EMAIL",
  "WEBEX (Space)",
  "WEBEX (Direct Message)",
  "WEBHOOK",
];

export const enabledOptions = [
  {
    name: "Enabled",
    value: true,
  },
  {
    name: "Disabled",
    value: false,
  },
];

export const yesNoOptions = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

export const webhookPayload = {
  timestamp:
    "A string in ISO 8601 format for the date and time that webhook was triggered.",
  type: "The trigger event (SCAN_COMPLETE, SCAN_ERROR, or SCAN_SUCCESS).",
  name: "The name of the notification that triggered.",
  description: "The description of the notification that triggered.",
  payload: {
    owner_app_id: "The app_id for the notification.",
    target_id: "The target_id for the notification.",
    scan_id: "The scan_id that triggered the notification.",
    scan_status: "The status of the scan that triggered the notification.",
    scan_type: "The type of scan that was run (i.e. solis_zap).",
    scan_link: "The link to the scan within Solis.",
  },
  notification_id: "The id of the notification that triggered.",
};

export const baseTriggerEvents = [
  {
    name: "On Scan Success",
    description:
      "Triggers notification when a scan finishes in a successful state.",
    data: {
      type: "SCAN_SUCCESS",
    },
  },
  {
    name: "On Scan Failure",
    description:
      "Triggers notification when a scan finishes in an errored state.",
    data: {
      type: "SCAN_ERROR",
    },
  },
  {
    name: "On Scan Complete",
    description: "Triggers notification when a scan finishes in any state.",
    data: {
      type: "SCAN_COMPLETE",
    },
  },
  {
    name: "On New Critical or High Results",
    description:
      "Triggers notification when a scan finishes with new failing results with a Critical or High severity.",
    data: {
      type: "SCAN_SUCCESS",
      collection: "RESULTS",
      collection_filter: {
        "latest_instance.status": "FAIL",
        instances: {
          $size: 1,
        },
        severity_value: {
          $gte: 7,
        },
      },
    },
  },
];

export const defaultDebounceInterval = OneSecondInMillis;

export const targetDeployEnvironments = [
  "PRODUCTION",
  "NON-PRODUCTION",
  "STAGE",
  "SANDBOX",
  "DEVELOPMENT",
];

export const appClassifications = [
  "ON-PREMISE",
  "CLOUD",
  "HYBRID CLOUD",
  "ENTERPRISE",
  "ENTERPRISE - IT",
];

export const ticketingSystems = ["STE"];

export const securityImpactRatings = ["HIGH", "MEDIUM", "LOW"];

export const resolutionSLAs = ["FIX_TARGET_SLA", "OPERATIONAL_MITIGATION_SLA"];

export const ticketingRuleArrayFields = [
  "scan_engines",
  "scanner_ids",
  "app_classifications",
  "deploy_environments",
];

export const patDaysValidDropdownOptions = [1];

export const destWithSecrets = ["WEBHOOK"];

export const targetTypes = ["web application", "api", "git repo"];

export const secretPlaceholder = "**********";

export const scanConfigValidationTabMap = {
  MALFORMED_SECRET_FIELD: ["Authentication"],
  MISSING_SECRET_KEY: ["Authentication"],
  NONEXISTENT_SECRET: ["Authentication"],
  INVALID_PARENT_DIR: ["Authentication", "Context"],
  MISSING_EXTERNAL_FILE_ENTRY: ["Authentication", "Context"],
  NONEXISTENT_FILE: ["Authentication", "Context"],
  MALFORMED_FORM_AUTH: ["Authentication"],
  MALFORMED_BEARER_AUTH: ["Authentication"],
  MALFORMED_OAUTH_AUTH: ["Authentication"],
  MALFORMED_MTLS_AUTH: ["Authentication"],
  MALFORMED_SESSION_TAKEOVER_AUTH: ["Authentication"],
  MALFORMED_SCRIPT_AUTH: ["Authentication"],
  MISSING_SCRIPT_PARAMS: ["Authentication"],
};

export const minScanConfigs = 1;

export const kbInBytes = 1024;

export const usageFileExtensions = {
  HAR_FILE: ["har"],
  OPENAPI: ["json", "yaml", "yml"],
  SCRIPT: ["groovy", "zst", "js"],
  SCRIPT_CONFIG: ["yaml", "yml"],
  SOAP_WSDL: ["xml"],
  URL_LIST: ["txt"],
};
